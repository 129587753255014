import styles from './SideNavigation.module.scss';
import { NavLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import Button from '../Button/Button';
import LogoutIcon from '../../assets/icons/LogoutIcon.js';
import { useState, useEffect } from 'react';
import LargeCloseIcon from '../../assets/icons/LargeCloseIcon.js';

import CustomDropdown from '../Dropdown'

import { useSelector, useDispatch } from 'react-redux';

import { toast } from 'react-toastify';

import Input from '../Input/Input.js';

import axiosRequest from '../../utils/request';


const SideNavigation = ({ menuOpen, setMenuOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(true);
  const user = useSelector(state => state.userData);
  const loggedIn = useSelector(state => state.loggedIn);

  function formatDate() {
    const d = new Date();
    const month = '' + (d.getMonth() + 1);
    const day = '' + d.getDate();
    const year = d.getFullYear();

    return [month.padStart(2, '0'), day.padStart(2, '0'), year].join('/');
  }

  async function logout() {
    try {
      navigate('/login');
      localStorage.removeItem('jwt');
      dispatch({
        data: null,
        type: 'UPDATE_USER_DATA'
      });

      dispatch({
        data: false,
        type: 'UPDATE_LOGGED_IN'
      });

      handleLogout();
    } catch (e) {
      console.log('ERROR: ', e);
    }
  };

  async function handleLogout() {
    try {
      const result = await axiosRequest('post', 'logout', {});
      if (result.data.success) {
        toast.info('Please log back in.');
        return;
      }
    } catch (e) {
      console.log('Error @ handleLogout -> ', e);
      return;
    }
  }

  const createOption = ['Character', 'Image'];

  const handleCreateOption = (option) => {
    switch (option) {
      case 'Character':
        navigate('/character/create');
        break;
      case 'Image':
        //toast.info('Coming Soon!');
        navigate('/image/create');
        break;
    }
  }

  return (
    <nav
      style={{}}
      className={clsx([styles.nav, menuOpen && styles.navOpen])}
      onMouseEnter={e => {
        e.preventDefault();
        //setHovered(true);
      }}
      onMouseLeave={e => {
        e.preventDefault();
        //setHovered(false);
      }}
    >
      <button className={styles.menuButton} onClick={() => setMenuOpen(false)}><LargeCloseIcon /></button>
      <div>
        <div className={styles['desktop-only']} style={{ margin: '20px' }}>
          {(
            <Button
              onClick={() => navigate('/')}
              className={styles.hoverScaleButton}
            >
              🏠
              {hovered ? ' Home' : ''}
            </Button>
          )}
        </div>
        <ul className={styles.scrollable}>
          <div className={clsx(styles.userArea, styles['mobile-only'])}>
            <div className={styles['mobile-only']} style={{ marginTop: '75px' }}>
              {(
                <Button
                  onClick={() => navigate('/')}
                  className={styles.hoverScaleButton}
                >
                  🏠
                  {hovered ? ' Home' : ''}
                </Button>
              )}
            </div>
          </div>
          {
              <>
                <li>
                  <NavLink
                    to={"/membership"}
                    className={({ isActive }) => clsx([isActive && styles.active])}
                  >
                    <span role="img" aria-label="Membership">💳</span>
                    {hovered ? <span>Membership</span> : ''}
                  </NavLink>
                </li>
                {!loggedIn ?  <li>
                  <NavLink
                    to={"/login"}
                    className={({ isActive }) => clsx([isActive && styles.active])}
                  >
                    <span role="img" aria-label="Membership">*</span>
                    {hovered ? <span> Login</span> : ''}
                  </NavLink>
                </li> : <></>}
                <>
              </>
              </>
          }
          <hr />
          {loggedIn ? <><li>
            <NavLink
              to={"#"}
              className={({ isActive }) => clsx([isActive && styles.activeCategory])}
            >
              <span style={{ fontSize: '32px' }} role="img" aria-label="For You">❤️</span>
              {hovered ? <span style={{ fontSize: '18px' }}>You</span> : ''}
            </NavLink>
          </li>
            <><li>
              <NavLink
                to={`/creator/${user ? user.username : ''}`}
                className={({ isActive }) => clsx([isActive && styles.active])}
              >
                <span role="img" aria-label="Profile">👤</span>
                {hovered ? <span>Profile</span> : ''}
              </NavLink>
            </li>
              <li>
                <NavLink
                  to={`/account`}
                  className={({ isActive }) => clsx([isActive && styles.active])}
                >
                  <span role="img" aria-label="Account">🧰</span>
                  {hovered ? <span>Manage Account</span> : ''}
                </NavLink>
              </li>
              <li>
                  <NavLink
                    to={"/buddies"}
                    className={({ isActive }) => clsx([isActive && styles.active])}
                  >
                    <span role="img" aria-label="My Buddies">🏆</span>
                    {hovered ? <span>My Buddies</span> : ''}
                  </NavLink>
                </li>
              <li>
                <NavLink
                  to={"/chats"}
                  className={({ isActive }) => clsx([isActive && styles.active])}
                >
                  <span role="img" aria-label="Chats">💬</span>
                  {hovered ? <span>Chats</span> : ''}
                </NavLink>
              </li>
              <li>
                  <NavLink
                    to={loggedIn ? '/' : "/login"}
                    className={({ isActive }) => clsx([isActive && styles.active])}
                    onClick={e => {
                      e.preventDefault();

                      if (loggedIn) {
                        navigate('/login');
                        
                        localStorage.removeItem('jwt');

                        dispatch({
                          data: null,
                          type: 'UPDATE_USER_DATA'
                        });

                        dispatch({
                          data: false,
                          type: 'UPDATE_LOGGED_IN'
                        });
                      } else {
                        navigate('/login');
                      }
                    }}
                  >
                    <span role="img" aria-label={loggedIn ? 'signout' : 'login'}>🛋️</span>
                    {hovered ? <span>{loggedIn ? 'Logout' : 'Login'}</span> : ''}
                  </NavLink>
                </li>  
            </>
          </> : <></>
          }
          <hr />
        </ul>
      </div>
    </nav>
  );
};

export default SideNavigation;