import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';


import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import Header from "../components/Header/Header";

import styles from "../assets/pages/Signup.module.scss";

import Input from '../components/Input/Input';
import Button from '../components/Button/Button';

import { containsNumber, containsCapitalLetter, isValidEmail } from '../utils/regex';
import axiosRequest from '../utils/request';

import DashboardLayout from '../components/DashboardLayout/DashboardLayout';

export default function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [username, setUsername] = useState('');
  const [ref, setRef] = useState('aibuddy');

  const [passwordMsg, setPasswordMsg] = useState('Password is too short!');
  const [passwordMsgStyle, setPasswordMsgStyle] = useState({ margin: '5px', color: 'red' });
  const [div1Style, setDiv1Style] = useState({});
  const [div2Style, setDiv2Style] = useState({});
  const [div3Style, setDiv3Style] = useState({});

  const [passwordValid, setPasswordValid] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    /*const url = window.location.href;
    const params = new URLSearchParams(new URL(url).search);
    const invCode = params.get('ref');
    if (invCode) {
      setRef(invCode);
    }*/
  }, []);

  useEffect(() => {
    validatePassword(password);
  }, [password]);

  useEffect(() => {
    validateConfirmedPassword(confirmedPassword);
  }, [confirmedPassword]);

  function validatePassword(pass) {
    setPasswordValid(false);
    if (pass.length < 8) {
      setPasswordMsg('Password is too short!');
      setPasswordMsgStyle({ margin: '5px', color: 'red' });
      setDiv1Style({ backgroundColor: '#b2b2b23c' });
      setDiv2Style({ backgroundColor: '#b2b2b23c' });
      setDiv3Style({ backgroundColor: '#b2b2b23c' });
      return;
    }

    setDiv1Style({ backgroundColor: 'green' });

    if (!containsCapitalLetter(pass)) {
      setPasswordMsg('Must contain a capital!');
      setPasswordMsgStyle({ margin: '5px', color: 'red' });
      setDiv2Style({ backgroundColor: '#b2b2b23c' });
      setDiv3Style({ backgroundColor: '#b2b2b23c' });
      return;
    }

    setDiv2Style({ backgroundColor: 'green' });

    if (!containsNumber(pass)) {
      setPasswordMsg('Must contain a number!');
      setPasswordMsgStyle({ margin: '5px', color: 'red' });
      setDiv3Style({ backgroundColor: '#b2b2b23c' });
      return;
    }

    setDiv3Style({ backgroundColor: 'green' });

    setPasswordMsg('Password looks good!');
    setPasswordMsgStyle({ margin: '5px', color: 'green' });
    setPasswordValid(true);
  }

  function validateConfirmedPassword(confPass) {
    setPasswordValid(false);
    if (confPass !== password) {
      setPasswordMsg("Passwords don't match!");
      setPasswordMsgStyle({ margin: '5px', color: 'red' });
      return;
    }

    validatePassword(confPass);
  }

  async function register() {
    try {
      if (!email || !password || !confirmedPassword || !username) {
        toast.error('Must fill out all fields!');
        return;
      }

      if (!isValidEmail(email)) {
        toast.error('Invalid email format!');
        return;
      }

      if (!passwordValid) {
        toast.error('Password does not meet requirements!');
        return;
      }

      let result = await axiosRequest('post', 'signup', { email, password, username, ref });

      if (result.data.success) {
        navigate('/login');
        toast.success('Welcome to AI Buddy!');
      } else {
        toast.error(result.data.msg);
      }
    } catch (e) {
      console.log('Error at sign up call -> ', e);
      toast.error('Unknown error signing up.');
    }
  }

  return (
    <DashboardLayout>
      <div className={styles.loginPage}>
        <div className={styles.container}>
          <div className={styles.card}>
            <div className={styles.text}>
              <p>Welcome to AI Buddy!</p>
              <p>Let's get started.</p>
            </div>
            <div className={styles.inputLabel}>
              <Input
                style={{ width: '100%', backgroundColor: '' }}
                value={email}
                onChange={e => setEmail(e.target.value)}
                label="Enter your email"
                titleWhite
                id={'email'}
                type={'email'}
              />
            </div>
            <div className={styles.inputLabel}>
              <Input
                style={{ width: '100%' }}
                value={password}
                onChange={e => setPassword(e.target.value)}
                label="Create a password"
                id={'password'}
                type={'password'}
                titleWhite
              />
            </div>
            <div className={styles.inputLabel}>
              <Input
                style={{ width: '100%' }}
                value={confirmedPassword}
                onChange={e => setConfirmedPassword(e.target.value)}
                label="Confirm your password"
                id={'confirmedPassword'}
                type={'password'}
                titleWhite
              />
            </div>
            <div className={styles.inputLabel}>
              <Input
                style={{ width: '100%' }}
                value={username}
                onChange={e => setUsername(e.target.value)}
                label="Username"
                id={'username'}
                type={'text'}
                titleWhite
              />
            </div>
            <div>
              <div style={{ marginTop: '12px' }} className={styles.row}>
                <div style={div1Style} className={styles.Jc3fE}></div>
                <div style={div2Style} className={styles.Jc3fE}></div>
                <div style={div3Style} className={styles.Jc3fE}></div>
              </div>
              <div style={passwordMsgStyle}>
                <h5>{passwordMsg}</h5>
              </div>
              <div style={{ marginLeft: '5px', marginTop: '25px', color: '#fff' }}>
                <small>Make sure it's at least 8 characters including a capital and number!</small>
              </div>
            </div>
            <Button
              style={{
                marginTop: '12px',
                width: '100%',
              }}
              onClick={register}
              disabled={!passwordValid}
            >
              Register
            </Button>
          </div>
          <div style={{
            margin: '10px',
            backgroundColor: '#000',
            backdropFilter: 'blur(5px)',
            padding: '8px',
            borderRadius: '15px',
            color: '#fff'
          }}>
            <small><b>By creating an account, you agree to the Terms of Service and Privacy Policy.</b></small>
          </div>
        </div>
      </div>
    </DashboardLayout>
  )
}