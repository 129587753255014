import DashboardLayout from '../../components/DashboardLayout/DashboardLayout';

export default function TermsOfService() {
  const terms = [
    {
      title: "Introduction to AIBuddy",
      content: "Welcome to AIBuddy, provided by Summit Ventures B.V. These Terms of Service ('TOS') govern your use of our website (https://www.AIBuddy.is), mobile applications, and services. By accessing or using AIBuddy, you agree to these TOS, our Privacy Policy, and Community Guidelines. AIBuddy is designed for users 18 and older. If you're under 18, please do not use our services."
    },
    {
      title: "Service Description",
      content: "AIBuddy offers an AI-powered platform for character creation and interaction. We use generative AI to enable conversations with user-designed characters and image generation based on text descriptions. While we strive for safety and appropriateness, we cannot guarantee the nature of AI-generated content. Users are encouraged to use these features responsibly."
    },
    {
      title: "User Accounts and Credits",
      content: "To access certain features, you must create an account using social login or email. Basic services are free, with limitations on character creation and message count. Additional features are available through in-app purchases or subscriptions, providing increased character limits and monthly message credits. Unused credits do not carry over to the next period."
    },
    {
      title: "Modifications to Service and TOS",
      content: "We reserve the right to modify or discontinue AIBuddy at any time, with or without notice. We may also update these TOS periodically. Continued use after changes constitutes acceptance of the new terms. We encourage you to review the TOS regularly."
    },
    {
      title: "External Links and Third-Party Content",
      content: "AIBuddy may contain links to external websites or platforms. We're not responsible for the content, privacy practices, or conduct of these third parties. Use caution when navigating away from our services and review the terms of any external sites you visit."
    },
    {
      title: "User Submissions and Content Rights",
      content: "By submitting content to AIBuddy, you grant us a worldwide, non-exclusive, royalty-free, perpetual, and transferable license to use, reproduce, distribute, and display your submissions. You retain ownership of your content, but we have the right to use it in connection with our services. We may remove or modify user content at our discretion."
    },
    {
      title: "Intellectual Property Rights",
      content: "AIBuddy's content, features, and functionality are protected by copyright, trademark, and other laws. You may not use our intellectual property without explicit permission. This includes, but is not limited to, our logo, design elements, and AI-generated content."
    },
    {
      title: "Prohibited Activities",
      content: "Users must not engage in illegal activities, hate speech, harassment, or distribution of harmful content. Prohibited actions include: sharing explicit sexual content, exploiting minors, violating intellectual property rights, impersonating others, and attempting to compromise our systems' security."
    },
    {
      title: "Privacy and Data Protection",
      content: "Your use of AIBuddy is also governed by our Privacy Policy. We collect and use data as described therein, in compliance with applicable laws. Users are responsible for maintaining the confidentiality of their account information."
    },
    {
      title: "Disclaimers and Warranties",
      content: "AIBuddy is provided 'as is' without warranties of any kind, express or implied. We do not guarantee uninterrupted, secure, or error-free service. We're not responsible for the accuracy of AI-generated content or user-submitted information."
    },
    {
      title: "Limitation of Liability",
      content: "To the fullest extent permitted by law, AIBuddy, its affiliates, and partners shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of our services. Our maximum liability is limited to the amount you paid us in the preceding 6 months."
    },
    {
      title: "Indemnification",
      content: "You agree to indemnify and hold AIBuddy harmless from any claims, losses, or damages arising from your use of our services, violation of these terms, or infringement of any third-party rights. This includes covering our legal fees and expenses in such cases."
    },
    {
      title: "Dispute Resolution and Governing Law",
      content: "These TOS are governed by Belgian law. Any disputes will be subject to the exclusive jurisdiction of the courts in Zaventem, Belgium. Users waive the right to participate in class actions against AIBuddy."
    },
    {
      title: "Termination of Service",
      content: "We may terminate or suspend your access to AIBuddy at our sole discretion, without prior notice, for conduct that we believe violates these TOS or is harmful to other users, us, or third parties, or for any other reason."
    },
    {
      title: "Refund and Cancellation Policies",
      content: "Refunds are generally not offered for paid services. Users can cancel recurring subscriptions at any time, effective at the end of the current billing cycle. Specific details can be found in our separate Refund and Cancellation policies."
    },
    {
      title: "Copyright and DMCA Policy",
      content: "We respect intellectual property rights and will respond to notices of alleged copyright infringement. To report violations, please contact our designated copyright agent with the required information as per our DMCA Policy."
    },
    {
      title: "Apple and Google Specific Terms",
      content: "When accessing AIBuddy through iOS or Android apps, additional terms from Apple or Google may apply. These are detailed in the full TOS and include specifics about licensing, maintenance, and third-party beneficiary rights."
    },
    {
      title: "International Use",
      content: "AIBuddy is based in Belgium and considered a passive service. Users accessing the service from other locations are responsible for compliance with local laws, if and to the extent local laws are applicable."
    },
    {
      title: "Severability and Waiver",
      content: "If any provision of these TOS is found to be unenforceable, the remaining provisions will continue in full force and effect. Our failure to enforce any right or provision of these TOS will not be considered a waiver of those rights."
    },
    {
      title: "Contact Information",
      content: "For questions about these TOS or AIBuddy's services, please contact us at team@AIBuddy.is or through the support section in our mobile applications."
    }
  ];

  return (
    <>
      <DashboardLayout title={'Terms of Service'}>
        <div style={{
          maxWidth: '100%',
          margin: '0 auto',
          padding: '20px',
          fontFamily: 'Arial, sans-serif',
          backgroundColor: '#121212a0',
        }}>
          <p style={{
            fontSize: '16px',
            lineHeight: '1.6',
            color: '#fff'
          }}>
            Last updated: {new Date().toLocaleDateString()}
          </p>
          <p style={{
            fontSize: '16px',
            lineHeight: '1.6',
            color: '#fff'
          }}>
            These Terms of Service outline the rules and regulations for the use of AIBuddy's website and services. 
            Please read these terms carefully to understand your rights and responsibilities as a user of our platform.
          </p>
          {terms.map((term, index) => (
            <div key={index} style={{
              marginBottom: '20px',
              padding: '15px',
              borderRadius: '5px'
            }}>
              <h2 style={{
                color: '#fff',
                fontSize: '20px',
                marginBottom: '10px',
                fontWeight: 'bold'
              }}>{term.title}</h2>
              <p style={{
                fontSize: '14px',
                lineHeight: '1.5',
                color: '#fff'
              }}>{term.content}</p>
            </div>
          ))}
          <p style={{
            fontSize: '16px',
            fontWeight: 'bold',
            textAlign: 'center',
            marginTop: '30px',
            color: '#fff'
          }}>
            By using AIBuddy, you agree to these Terms of Service.
          </p>
        </div>
      </DashboardLayout>
    </>
  )
}