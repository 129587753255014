import { createBrowserRouter } from 'react-router-dom';

import Signup from './pages/signup.js';
import Login from './pages/login.js';

/**   */
import Home from './pages/home.js';
import CreateChar from './pages/create-char.js';
import CharacterChat from './pages/character-chat.js';

import UserProfile from './pages/user-profile.js';
import UserChats from './pages/user-chats.js';

import YourCharacters from './pages/your-characters.js'

import UserMembership from './pages/membership/user-membership.js';
import ManageMembership from './pages/membership/manage-membership.js';

import TermsOfService from './pages/legal/terms.js';
import PrivacyPolicy from './pages/legal/Privacy.js';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/legal/terms",
    element: <TermsOfService />,
  },
  {
    path: "/legal/privacy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/buddies",
    element: <YourCharacters />,
  },
  {
    path: '/buddy/create',
    element: <CreateChar/>
  },
  {
    path: "creator/:username",
    element: <UserProfile />,
  },
  {
    path: '/chats',
    element: <UserChats/>
  },
  {
    path: '/membership',
    element: <UserMembership/>
  },
  {
    path: '/account',
    element: <ManageMembership/>
  },
  {
    path: 'buddy/:charactername/chat',
    element: <CharacterChat/>
  },
]);

export default router;
