import { useState, useEffect } from 'react';
import DashboardLayout from '../components/DashboardLayout/DashboardLayout';
import styles from '../assets/pages/UserProfile.module.scss';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import UserAvatar from '../components/UserAvatar';
import axiosRequest from '../utils/request';

import updateImageUrl from '../utils/updateImageUrl';

export default function UserProfile() {
  const navigate = useNavigate();
  const { username } = useParams();

  const [activeTab, setActiveTab] = useState('Buddies');
  const [isOwnProfile, setIsOwnProfile] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isFollowing, setIsFollowing] = useState(false);

  const [followers, setFollowers] = useState([]);
  const [followersPage, setFollowersPage] = useState(1);
  const [totalFollowersPages, setTotalFollowersPages] = useState(1);

  const [following, setFollowing] = useState([]);
  const [followingPage, setFollowingPage] = useState(1);
  const [totalFollowingPages, setTotalFollowingPages] = useState(1);

  const tabs = ['Buddies', 'Achievements'];

  const user = useSelector(state => state.userData);
  const loggedIn = useSelector(state => state.loggedIn)

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        setLoading(true);
        const startTime = Date.now();
        const isOwnProfile = user && user.username === username;
        setIsOwnProfile(isOwnProfile);

        const jwt = localStorage.getItem('jwt');
        const endpoint = `user/${username}${isOwnProfile ? '?isOwnProfile=true' : ''}`;

        const result = await axiosRequest('get', endpoint, null, jwt);

        if (result.data) {
          setProfileData(result.data);

          if(loggedIn) {
            let isFollowingCheck = user.followedCreators.includes(result.data['_id']);
            setIsFollowing(isFollowingCheck);
          }
        } else {
          throw new Error('Failed to fetch profile data');
        }

        const endTime = Date.now();
        const loadingTime = endTime - startTime;
        if (loadingTime < 500) {
          await new Promise(resolve => setTimeout(resolve, 500 - loadingTime));
        }
      } catch (error) {
        console.error('Error fetching profile data:', error);
        // Handle error (e.g., show error message to user)
      } finally {
        setLoading(false);
      }
    };

    if (username) {
      fetchProfileData();
    }
  }, [username, user]);

  useEffect(() => {
    if (activeTab === 'Followers' && followers.length === 0) {
      fetchFollowers();
    }

  }, [activeTab]);

  const handleFollowUnfollow = async () => {
    try {
      const jwt = localStorage.getItem('jwt');

      console.log('Profile Data -> ', profileData);

      const endpoint = `user/${profileData._id}/follow`;
      const result = await axiosRequest('post', endpoint, null, jwt);

      if (result.data) {
        setIsFollowing(result.data.isFollowing);
        setProfileData(prevData => ({
          ...prevData,
          followers: result.data.isFollowing ? prevData.followers + 1 : prevData.followers - 1
        }));
      } else {
        throw new Error('Failed to update follow status');
      }
    } catch (error) {
      console.error('Error updating follow status:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const getFollow = async (userId, page = 1, limit = 10) => {
    try {
      const jwt = localStorage.getItem('jwt');
      const response = await axiosRequest('get', `user/${userId}/followers?page=${page}&limit=${limit}`, null, jwt);
      if (response.data) {
        // Handle the followers data
        console.log(response.data);
        return response.data;
      }
    } catch (error) {
      console.log('Error fetching followers:', error);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  const fetchFollowers = async () => {
    const result = await getFollow(profileData._id, followersPage);
    if (result) {
      try {
        console.log('Result =? ', result)
        setFollowers(prevFollowers => [...prevFollowers, ...result.followers]);
        setTotalFollowersPages(result.totalPages);
      } catch (e) {

      }
    }
  };

  if (loading) {
    return (
      <DashboardLayout title="Loading...">
        <div className={styles.profileContainer}>
          <div className={styles.skeletonProfileHeader}>
            <div className={styles.skeletonProfileImage}></div>
            <div className={styles.skeletonProfileInfo}>
              <div className={styles.skeletonText}></div>
              <div className={styles.skeletonText}></div>
            </div>
          </div>
          <div className={styles.skeletonProfileActions}>
            <div className={styles.skeletonButton}></div>
            <div className={styles.skeletonButton}></div>
          </div>
          <div className={styles.skeletonTabContainer}>
            {tabs.map((_, index) => (
              <div key={index} className={styles.skeletonTab}></div>
            ))}
          </div>
          <div className={styles.skeletonTabContent}>
            <div className={styles.skeletonCharactersGrid}>
              {[...Array(6)].map((_, index) => (
                <div key={index} className={styles.skeletonCharacterCard}>
                  <div className={styles.skeletonCharacterImage}></div>
                  <div className={styles.skeletonCharacterInfo}>
                    <div className={styles.skeletonText}></div>
                    <div className={styles.skeletonText}></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </DashboardLayout>
    );
  }

  if (!profileData) {
    return <DashboardLayout title="Error"><div>Failed to load profile data.</div></DashboardLayout>;
  }

  return (
    <DashboardLayout title={'Profile'}>
      <div className={styles.profileContainer}>
        <div className={styles.profileHeader}>
          <div className={styles.profileImage}>
            {profileData.pfp ? (
              <img src={profileData.pfp} alt={`${profileData.username}'s avatar`} />
            ) : (
              <UserAvatar style={{
                width: '150px',
                aspectRatio: '1 / 1'
              }} user={profileData} />
            )}
          </div>
          <div className={styles.profileInfo}>
            <h1>{profileData.username}</h1>
            <p>{isOwnProfile ? `${profileData.email} •` : ''}  {profileData.characters?.length || 0} buddies</p>
            <p style={{ color: '#fff', marginTop: '10px' }}>{profileData.bio}</p>
          </div>
        </div>
        <div className={styles.profileActions}>
          {isOwnProfile ? (
            <>
              <button 
                className={styles.actionButton}
                onClick={e => {
                  e.preventDefault();
                  navigate('/my-characters');
                }}
                style={{ color: '#fff', backgroundColor: '#000', border: '2px solid #fff' }}
              >My Buddies</button>
              <button 
                className={styles.actionButton}
                onClick={e => {
                  e.preventDefault();
                  navigate('/account');
                }}
                style={{ color: '#000', backgroundColor: '#C3DDFF' }}
              >Edit Profile</button>
            </>
          ) : (
            loggedIn ?  <button
              className={`${styles.actionButton} ${isFollowing ? styles.unfollowButton : styles.followButton}`}
              onClick={handleFollowUnfollow}
            >
              {isFollowing ? 'Unfollow' : 'Follow'}
            </button> : <></>
          )}
        </div>
        <div className={styles.tabContainer}>
          {tabs.map((tab) => (
            <button
              key={tab}
              className={`${styles.tabButton} ${activeTab === tab ? styles.activeTab : ''}`}
              onClick={() => handleTabClick(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
        <div className={styles.tabContent}>
          {activeTab === 'Buddies' && (
            profileData.characters && profileData.characters.length > 0 ? (
              <div className={styles.charactersGrid}>
                {profileData.characters.map((character) => (
                  <div
                    key={character._id}
                    className={styles.characterCard}
                    onClick={e => {
                      e.preventDefault();
                      navigate(`/buddy/${character['_id']}`)
                    }}
                  >
                    <img src={updateImageUrl(character.imageUrl)} alt={character.name} />
                    <h3>{character.name}</h3>
                    <p>{character.description}</p>
                    <div className={styles.characterStats}>
                      <span>👍 {character.likes}</span>
                      <span>👎 {character.dislikes}</span>
                      <span>👥 {character.follows}</span>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.emptyState}>
                <p>It's a bit empty here</p>
                <p>Create your first AI Buddy</p>
                <button 
                  className={styles.createButton}
                  onClick={e => {
                    e.preventDefault();

                    navigate('/buddy/create')
                  }}
                >
                    Create Buddies
                </button>
              </div>
            )
          )}
          {activeTab === 'Achievements' && (
              <div className={styles.emptyState}>
                <p>Achievments coming soon.</p>
                <p>Stay tuned!</p>
                <button className={styles.createButton}>⏳</button>
              </div>
            )}
        </div>
      </div>
    </DashboardLayout>
  );
}