export const gender = ['female', 'male'];

export const face = [
  "Default",
  "Smug",
  "Blush",
  "Calm",
  "Smile",
  "Surprised",
  "Shocked",
  "Looking at Viewer",
  "Joyful"
];

export const age = ['18', '20s', '30s', '40s', '50s'];

export const ethnicity = [
  "European",
  "Indian",
  "African",
  "Asian",
  "Latina",
  "Arabian"
];

export const hairColor = [
  "Multicolored",
  "Black",
  "White",
  "Blonde",
  "Brunette",
  "Purple",
  "Pink"
];

export const sfwPose = ['sitting on couch', 'reaching out to hug', 'portrait photo', 'sleeping next to you', 'selfie', 'shopping', 'dancing', 'tennis', 'yoga'];


export const bodyType = ['skinny', 'fit', 'chubby', 'fat', 'muscular'];

export const style = ['anime', 'realistic'];