import axios from 'axios';

export default async function axiosRequest(method, endpoint, body = null, token, isFormData = false) {
  try {
    //let url = `http://localhost:8080/api/${endpoint}`;
    let url =`https://api.aibuddy.fun/api/${endpoint}`;

    console.log('token -> ', token)

    const headers = {
      'accept': 'application/json',
    }

    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }

    if (!isFormData) {
      headers['content-type'] = 'application/json';
    }

    let options = {
      method: method,
      url,
      headers,
      validateStatus: () => true
    };

    if (method.toLowerCase() === 'post' && body) {
      options.data = body;
    }

    if (isFormData) {
      options.data = body;
    }

    let { data, status } = await axios(options);
    
    return { data, status };
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};