import styles from './DashboardLayout.module.scss';

import SideNavigation from '../SideNavigation/SideNavigation';
import { useEffect, useState, useRef } from 'react';
import MenuIcon from '../../assets/icons/MenuIcon.js';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from "jwt-decode";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';

import axiosRequest from '../../utils/request';

import Button from '../Button/Button.js';

export default function DashboardLayout({ disregardHeight, title, RightContent, OuterContent, isBurn, children, subtitle, headerStyle, hideFooter }) {

  const [menuOpen, setMenuOpen] = useState(false);
  const lockIfUnauthorized = ['account', 'billing', 'settings', 'for-you', 'following', 'my-characters', 'favorites', '/chat']

  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const user_data = useSelector(state => state.userData);
  const loggedIn = useSelector(state => state.loggedIn);
  const nsfw = useSelector(state => state.nsfw);
  const [nsfwEnabled, setNSFW] = useState(nsfw);

  const token = localStorage.getItem('jwt');
  const [authorized, setAuthorized] = useState(true);

  const [showAgeModal, setShowAgeModal] = useState(false);

  useEffect(() => {
    setNSFW(nsfw);
  }, [nsfw]);

  const handleNSFW = () => {
    if (!nsfwEnabled) {
      setShowAgeModal(true);
    } else {
      updateNSFW(false);
    }
  };

  const handleAgeConfirm = () => {
    setShowAgeModal(false);
    updateNSFW(true);
  };

  const updateNSFW = (value) => {
    dispatch({
      type: 'UPDATE_NSFW',
      data: value
    });
    setNSFW(value);
  };

  async function logout(fullPath) {
    try {
      navigateTo('/login', { fullPath });

      localStorage.removeItem('jwt');
      dispatch({
        data: null,
        type: 'UPDATE_USER_DATA'
      });

      dispatch({
        data: false,
        type: 'UPDATE_LOGGED_IN'
      });

      handleLogout();
    } catch (e) {
      console.log('ERROR: ', e);
    }
  };

  async function handleLogout() {
    try {
      const result = await axiosRequest('post', 'logout', {});
      if (result.data.success) {
        toast.info('Please log back in.');
        return;
      }
    } catch (e) {
      console.log('Error @ handleLogout -> ', e);
      return;
    }
  }

  useEffect(() => {
    document.title = title + ' - Mindscape';

    /*let url = window.location.href.split('/')[3];
    let lockedPage = lockIfUnauthorized.includes(url);

    if(window.location.href.includes('/character/create')) {
      lockedPage = true;
    };*/

    let url = window.location.href;

    const fullPath = location.pathname;

    let lockedPage = false;
    let x, length;
    for (x = 0, length = lockIfUnauthorized.length; x < length; x += 1) {
      let toCheck = lockIfUnauthorized[x];

      if (url.includes(toCheck)) {
        lockedPage = true;
        break;
      }
    }

    if ((user_data == null || token == null) && lockedPage) {
      dispatch({
        data: false,
        type: 'UPDATE_LOGGED_IN'

      });
      dispatch({
        data: null,
        type: 'UPDATE_USER_DATA'
      });

      navigateTo('/login', { state: { fullPath } });
      toast.error('Please login again.');
      return;
    } else if (token != null && lockIfUnauthorized.includes(url)) {

      let decodedToken = jwtDecode(token);

      if (decodedToken.exp * 1000 < new Date().getTime()) {
        logout(fullPath);
        return;
      };
    };

    setAuthorized(true);
  }, []);


  useEffect(() => {
    document.title = (title ? `${title} - ` : '') + 'Mindscape';
  }, [title]);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const jwt = localStorage.getItem('jwt');
        const endpoint = `user/${user_data.username}${'?isOwnProfile=true'}`;

        const { data, status } = await axiosRequest('get', endpoint, null, jwt);

        if (status == 200 && data) {
          dispatch({ type: 'UPDATE_USER_DATA', data: data });
        } else {
          //throw new Error('Failed to fetch profile data');
        }
      } catch (error) {
        console.error('Error fetching profile data:', error);
        //toast.error('Error fetching profile data.')
      };
    };
    if (loggedIn) {
      try {
        fetchProfileData()
      } catch (e) {

      }
    }
  }, [])


  return (
    <>
      {!authorized ? <></> :
        <>
          <div
            style={{
              position: 'relative',
              display: 'flex',
              minHeight: '98vh',
              width: '100vw',
              padding: '10px',
              height: '100vh',
              overflow: 'hidden',
              backgroundColor: '#000',
            }}
          >
            <div
              style={{
                backgroundImage: 'url("/landing_hero.png")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '98vh',
                width: '100%',
                position: 'relative',
                display: 'flex',
                minHeight: '100%',
                width: '100%',
                flex: 1,
                overflow: 'hidden',
              }}
            >
              <div className="sticky top-[14px] z-10 sm:top-[13px]">
                <div className="relative">
                  <header className="relative flex justify-between">
                    <div className="flex flex-1 items-center justify-between gap-5 overflow-x-hidden">
                      <div className="flex flex-1 items-center justify-between sm:justify-start">
                        <nav className="ml-6 items-center gap-2 xs:flex" style={{ display: 'flex', flexDirection: 'row', color: '#fff', textShadow: '1px 2px 3px rgba(0, 0, 0, 0.25)', fontSize: '16px', fontWeight: 'bold', textTransform: 'uppercase' }}>
                          <div
                            className="px-2 py-2 sm:px-4"
                            onClick={e => {
                              e.preventDefault();
                              navigateTo('/');
                            }}
                            style={{ cursor: 'pointer', fontSize: '32px' }}
                          >
                            ❤️‍🔥 AI Buddy
                          </div>
                          <div>
                            <Button
                              onClick={() => navigateTo('/buddy/create')}
                              className="bg-pink-400 hover:bg-blue-300 text-white font-bold py-2 px-4 rounded"
                              style={{
                              }}
                            >
                              + Create
                            </Button>
                          </div>
                          {<button className={styles.menuButton} onClick={() => setMenuOpen(true)}>
                            <MenuIcon />
                          </button>}
                        </nav>
                      </div>
                      <div className="block transition-transform duration-500 xs:hidden rotate-0">
                        <button aria-label="Open Navigation menu" className="text-yellow-300 uppercase flex items-center gap-1 disabled:cursor-not-allowed">
                          <svg viewBox="0 0 24 24" style={{ height: "24px", width: "24px" }}>
                            <use xlinkHref="/icons/library.svg#menu"></use>
                          </svg>
                        </button>
                      </div>
                      <div className="hidden overflow-x-auto xs:gap-10 sm:flex"></div>
                    </div>
                  </header>
                  <div className={styles.dashboard}>
                    <SideNavigation menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
                    <main>
                      <div style={disregardHeight ? { height: '100vh' } : {}} className={styles.container}>
                        <header style={headerStyle} className={styles.header}>
                          {!title ? <></> : <div
                            className={styles.column}
                            style={{ maxWidth: '650px' }}
                          >
                            <h1 style={{ fontSize: '24px' }}>{title}</h1>
                            <h4 style={{ fontSize: '14px', color: '#fff' }}>{subtitle}</h4>
                          </div>}
                          <div>
                            {RightContent}
                          </div>
                        </header>
                        {children}
                        {hideFooter ? <></> : <div className={styles.footer}>
                          <div className={styles.footerContent}>
                            <div className={styles.row}>
                              <h1 style={{ fontSize: '18px', color: '#fff', fontWeight: 'bold' }}>❤️‍🔥 AI Buddy</h1>
                            </div>
                            <p style={{ width: '425px' }}>AI Buddy is a platform that allows you to chat with and create an endless amount of AI buddies.</p>
                          </div>
                          <div className={styles.footerLinks}>
                            <div>
                              <h4>PLATFORM</h4>
                              <ul>
                                <li
                                  onClick={e => {
                                    e.preventDefault();
                                    navigateTo('/buddies')
                                  }}
                                >
                                  AI Buddy chat
                                </li>
                              </ul>
                            </div>
                            <div>
                              <h4>RESOURCES</h4>
                              <ul>
                                <li
                                  onClick={e => {
                                    e.preventDefault();
                                    toast.info('Affiliate program coming soon!');
                                  }}
                                >Become an Affiliate</li>
                                <li>Support</li>
                                <li
                                  onClick={e => {
                                    e.preventDefault();
                                    navigateTo('/legal/privacy')
                                  }}
                                >Privacy Policy</li>
                                <li
                                  onClick={e => {
                                    e.preventDefault();
                                    navigateTo('/legal/terms')
                                  }}
                                >Terms of Service</li>
                              </ul>
                            </div>
                          </div>
                        </div>}
                      </div>
                    </main>
                    {OuterContent}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backdropFilter: 'blur(.5px)',
              backgroundColor: 'rgba(0, 0, 0, 0.65)',
              zIndex: 2
            }}
          ></div>
        </>
      }
    </>
  );
}
