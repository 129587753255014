import { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DashboardLayout from '../components/DashboardLayout/DashboardLayout';
import styles from '../assets/pages/CharacterChat.module.scss';
import LabeledCheckbox from '../components/Checkbox/LabeledCheckbox';
import axiosRequest from '../utils/request';
import { useSelector } from 'react-redux';
import Typewriter from 'typewriter-effect';

import updateImageUrl from '../utils/updateImageUrl';

import CustomDropdown from '../components/Dropdown';
import SubscribeModal from '../components/SubscribeModal';

import { toast } from 'react-toastify';

export default function CharacterChat() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(true);
  const [messages, setMessages] = useState([]);
  const [character, setCharacter] = useState(null);
  const [nsfw, setNSFW] = useState(true);
  const [chatId, setChatId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [newMessage, setNewMessage] = useState(null);

  const [msgType, setMsgType] = useState('Chat');
  const msgOptions = ['Chat', 'Image'];



  const chatBoxRef = useRef(null);
  const { charactername } = useParams();

  const user = useSelector(state => state.userData);

  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    const fetchCharacterAndChat = async () => {
      try {
        setLoading(true);
        const jwt = localStorage.getItem('jwt');
        let promises = [
          axiosRequest('get', `character/${charactername}`, null, jwt),
          axiosRequest('get', `chats/${charactername}/messages`, null, jwt)
        ];
        let [characterResponse, messagesResponse] = await Promise.all(promises);
        setCharacter(characterResponse.data.character);
        
        if (messagesResponse.data.length === 1) {
          // If there's only one message, set it as newMessage to be typed out
          const firstMessage = messagesResponse.data[0];
          setNewMessage({ content: firstMessage.content, senderType: 'character' });
          setMessages([]);
        } else {
          // If there are multiple messages, just set them all in the messages state
          setMessages(messagesResponse.data.reverse());
        }
        
        setChatId(messagesResponse.data[0]?.chatId);
      } catch (err) {
        setError("Failed to load chat. Please try again.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
  
    if (charactername) {
      fetchCharacterAndChat();
    }
  }, [charactername]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages, newMessage]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
  
    if(user.subscriptionStatus == 'inactive'  && messages.length > 1) {
      setShowSubModal(true)
      return;
    };
  
    const messageInput = e.target.elements.messageInput;
    const newUserMessage = messageInput.value.trim();
    if (newUserMessage && chatId) {
      try {
        const jwt = localStorage.getItem('jwt');
        setMessages(prevMessages => [
          { content: newUserMessage, senderType: 'user' },
          ...prevMessages,
        ]);
        messageInput.value = '';
        setIsTyping(true);
        const response = await axiosRequest('post', `chats/${chatId}/messages`, { content: newUserMessage, description: character.description }, jwt);
        setIsTyping(false);
        if(response.data.aiMessage) {
          setNewMessage({ content: response.data.aiMessage.content, senderType: 'character' });
        } else {
          toast.error(response.data.message)
        }
      } catch (err) {
        console.error("Failed to send message:", err);
        setIsTyping(false);
      }
    }
  };

  const handleTypingComplete = () => {
    if (newMessage) {
      setMessages(prevMessages => [newMessage, ...prevMessages]);
      setNewMessage(null);
    }
  };

  const [showContinueButton, setShowContinueButton] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded(!isExpanded);


  useEffect(() => {
    if (messages.length > 0 && messages[0].senderType === 'character' && !isTyping && !newMessage) {
      setShowContinueButton(true);
    } else {
      setShowContinueButton(false);
    }
  }, [messages, isTyping, newMessage]);

  const handleContinue = async () => {
    if (chatId) {
      try {
        if(user.subscriptionStatus == 'inactive' && messages.length > 1) {
          setShowSubModal(true)
          return;
        };
      
        const jwt = localStorage.getItem('jwt');
        setIsTyping(true);
        setShowContinueButton(false);
        const response = await axiosRequest('post', `chats/${chatId}/messages`, { content: "go on...", description: character.description, isContinue: true }, jwt);
        setIsTyping(false);
        setNewMessage({ content: response.data.aiMessage.content, senderType: 'character' });
      } catch (err) {
        console.error("Failed to send continue message:", err);
        setIsTyping(false);
      }
    }
  };

  const [showSubModal, setShowSubModal] = useState(false);

  const handleShowSubModal = () => {
    navigate('/membership')
  };

  const chatOptions = ['🆕 New Chat', '🔗 Share Chat', '🗑️ Delete Chat'];
  const [selectedChatOption, setSelectedChatOption] = useState('Start New Chat');

  async function handleSetSelectedChatOption(e) {
    try {
      //e.preventDefault();
      toast.info('Update coming this week for that!')
    } catch(e) { 

    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      const newUserMessage = e.target.value.trim();
      if (newUserMessage) {
        // Create a synthetic event object
        const syntheticEvent = {
          preventDefault: () => {},
          target: {
            elements: {
              messageInput: e.target
            }
          }
        };
        handleSendMessage(syntheticEvent);
      }
    }
  };

  if (loading) return <DashboardLayout title="Loading..."><div>Loading chat...</div></DashboardLayout>;
  if (error) return <DashboardLayout title="Error"><div>{error}</div></DashboardLayout>;
  if (!character) return <DashboardLayout title="Not Found"><div>Character not found.</div></DashboardLayout>;

  return (
    <>
      {<DashboardLayout hideFooter={false} title={''} disregardHeight={true} headerStyle={{ padding: '0px', marginBottom: '0px' }}>
        <div className={styles.chatPageContainer}>
          <div className={styles.chatContainer}>
            <div className={styles.header}>
              <img src={updateImageUrl(character.imageUrl)} alt={character.name} className={styles.characterImage} />
              <div className={styles.characterInfo}>
                <h2
                  onClick={e => {
                    e.preventDefault();
                    navigate(`/character/${character['_id']}`)
                  }}
                >{character.name}</h2>
                <p
                  onClick={e => {
                    e.preventDefault();
                    navigate(`/creator/${character.creator.username}`)
                  }}
                >Created by {character.creator.username}</p>
              </div>
              <div className={styles.disclaimerDropdown}>
                <div className={styles.disclaimerToggle} onClick={toggleDropdown}>
                  <span className={styles.disclaimerIcon}>ⓘ</span>
                  <span className={styles.disclaimerText}>Read our safe chat disclaimers</span>
                  <span className={styles.expandIcon}>{isOpen ? '▲' : '▼'}</span>
                </div>
                {isOpen && (
                  <div className={styles.disclaimerContent}>
                    <p>Remember: You're entering the realm of AI-driven, fictional roleplay, where every scenario unfolds with explicit consent. Treat this as a creative space, detached from reality. Engaging here means you commit to our safe chat rules and legal boundaries, avoiding any forbidden topics. Dive into your story, safely and imaginatively!</p>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.chatBoxWrapper}>
              <div className={styles.chatBox} ref={chatBoxRef}>
                {isTyping && (
                  <div key={messages.length - 1} className={`${styles.message} ${styles.character}`}>
                    <img src={updateImageUrl(character.imageUrl)} alt={character.name} className={styles.messageAvatar} />
                    <div className={styles.messageContent}>
                      <p>Typing...</p>
                    </div>
                  </div>
                )}
                {newMessage && (
                  <div key={messages.length - 1} className={`${styles.message} ${styles.character}`}>
                    <img src={updateImageUrl(character.imageUrl)} alt={character.name} className={styles.messageAvatar} />
                    <div className={styles.messageContent}>
                      <Typewriter
                        options={{
                          string: newMessage.content,
                          autoStart: true,
                          delay: 22,
                          cursor: '',
                        }}
                        onInit={(typewriter) => {
                          typewriter
                            .typeString(newMessage.content)
                            .callFunction(() => {
                              handleTypingComplete();
                            })
                            .start();
                        }}

                      />
                    </div>
                  </div>
                )}
                {showContinueButton && (
                  <div className={styles.continueButtonWrapper}>
                    <button onClick={handleContinue} className={styles.continueButton}>
                      Continue →
                    </button>
                  </div>
                )}
                {messages.map((message, index) => (
                  <div key={index} className={`${styles.message} ${styles[message.senderType]}`}>
                    {message.senderType === 'character' && (
                      <img src={updateImageUrl(character.imageUrl)} alt={character.name} className={styles.messageAvatar} />
                    )}
                    <div className={styles.messageContent}>
                      <p>{message.content}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.inputArea}>
              <form onSubmit={handleSendMessage}>
                <div className={`${styles.inputRow} ${isExpanded ? styles.expanded : ''}`}>
                  <div className={styles.topInputSection}>
                    <CustomDropdown
                      options={msgOptions}
                      value={msgType}
                      onChange={handleSetSelectedChatOption} // SHOULD BE NEW FUNCTION USING STATE SETTER setMsgType
                      chat={true}
                    />
                    <div className={styles.inputWrapper}>
                      <textarea
                        name="messageInput"
                        placeholder="Type a message..."
                        className={styles.messageInput}
                        rows={isExpanded ? "3" : "1"}
                        onKeyDown={handleKeyDown}
                      />
                      <button type="button" onClick={toggleExpand} className={styles.expandButton}>
                        {'\u26F6'}
                      </button>
                    </div>
                  </div>
                </div>
                <div className={styles.bottomInputSection}>
                  <div className={styles.leftControls}>
                    <CustomDropdown
                      options={chatOptions}
                      value={'🛠️'}
                      onChange={handleSetSelectedChatOption}
                      chat={true}
                      removeArrow
                      emojiSelector
                    />
                  </div>
                  <div className={styles.rightControls}>
                    <button 
                      type="button" 
                      className={styles.voiceButton}
                      onClick={handleSetSelectedChatOption}
                    >🎤</button>
                    <button type="submit" className={styles.sendButton}>▶</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </DashboardLayout>}
      {
        <SubscribeModal
          isOpen={showSubModal}
          onClose={() => setShowSubModal(false)}
          onConfirm={handleShowSubModal}
          bgImg={updateImageUrl(character.imageUrl)}
        />
      }
    </>
  );
}