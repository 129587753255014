import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from '../components/DashboardLayout/DashboardLayout';
import styles from '../assets/pages/Home.module.scss';

export default function Home() {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(true);

  return (
    <>
      {!loggedIn ? (
        <></>
      ) : (
        <DashboardLayout hideFooter={true}>
          <div className={styles.header}>
            <div className={styles.content}>
              <h2>Forge Your Perfect Digital Companion</h2>
              <p>Powered by <b style={{ fontSize: '24px' }}>❤️‍🔥 AI Buddy</b></p>
              <p>
                Unleash your imagination with AI Buddy's cutting-edge creator. 
                Craft a unique persona, tailor their looks, and build a rich 
                backstory. Your AI adventure begins in mere moments!
              </p>
              <button className={styles.createButton} onClick={() => navigate('/signup')}>
                Start Your Free AI Journey Now!
              </button>
            </div>
          </div>
          <div className={styles.aboutSection}>
            <h2>Discover AI Buddy</h2>
            <h3>What sets AI Buddy apart?</h3>
            <p>
              AI Buddy is a revolutionary platform where AI meets imagination! 
              We pride ourselves on lightning-fast text generation and 
              multilingual capabilities. Soon, you'll chat in Spanish, Chinese, 
              and more! Premium users can even request custom images, taking 
              immersion to the next level!
            </p>
            <h3>How do AI Buddy Subscriptions and Message Bundles work?</h3>
            <p>
              Elevate your experience with AI Buddy Subscriptions - enjoy 
              perks like turbo-charged messaging and enhanced AI responses. 
              Need a quick boost? Grab a Message Bundle to extend your chat 
              limits without committing to a full subscription.
            </p>
            <h3>What adventures await on AI Buddy?</h3>
            <p>
              Dive in and create your AI companion for free! Explore endless 
              scenarios and let your imagination run wild. Your AI buddy can 
              even share images to enrich your chats. Remember, all content is 
              AI-generated and fictional - we prioritize creativity within 
              safe and legal boundaries.
            </p>
            <h3>How does AI Buddy protect my privacy?</h3>
            <p>Your privacy is our priority. Check out our comprehensive policy <a href="#">here</a>.</p>
            <h3>What are AI Buddy's ground rules?</h3>
            <p>For a smooth experience, please review our terms of service <a href="#">here</a>.</p>
          </div>
        </DashboardLayout>
      )}
    </>
  );
}