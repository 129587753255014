import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import DashboardLayout from '../../components/DashboardLayout/DashboardLayout';
import styles from '../../assets/pages/UserMembership.module.scss';
import axiosRequest from '../../utils/request';

import { useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

import { toast } from 'react-toastify';

const stripePromise = loadStripe('pk_live_51Pz7XkDqfFzJp3s79awxESHy1UD9Dvc0uRKd1x5P4WgARHQNfF7LYB8qe6D4Budfyj9GhAXYxYUdkQsnZhXMZlAm00NVS0TaxW');

export default function UserMembership() {
  const navigate = useNavigate();
  const loggedIn = useSelector(state => state.loggedIn);
  const user = useSelector(state => state.userData);

  const [selectedBilling, setSelectedBilling] = useState('monthly');
  const [openFAQ, setOpenFAQ] = useState(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [selectedPlan, setSelectedPlan] = useState('Deluxe');
  const [params, setParams] = useState({ userId: null, checkout: null });
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const url = window.location.href;
    const urlParams = new URLSearchParams(new URL(url).search);
    const userId = urlParams.get('id');
    const checkout = urlParams.get('checkout');
    const priceId = urlParams.get('priceId');

    setParams({ userId, checkout });
    setIsLoaded(true);

    if (userId) {
      handleSubscribe(userId, priceId);
    }

    if (checkout) {
      window.open('https://mindscape.is/account', '_self');
    };
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  const faqItems = [
    {
      question: "Why was my payment card declined?",
      answer: "Your card may have been declined due to insufficient funds, expired card details, or security measures by your bank. Please check your card details and try again, or contact your bank for assistance."
    },
    {
      question: "Do you offer PayPal?",
      answer: "We currently do not offer PayPal as a payment method. We use Whop for secure payment processing."
    },
    {
      question: "How do you ensure my data's integrity?",
      answer: "We use industry-standard encryption and security measures to protect your data. All transactions are processed securely through Whop."
    },
    {
      question: "How do I cancel my subscriptions?",
      answer: "You can cancel your subscription at any time from your account settings. Your access will continue until the end of your current billing period."
    },
    {
      question: "Is cryptocurrency a valid payment method?",
      answer: "We do not currently accept cryptocurrency as a payment method. We use Whop for all transactions."
    },
    {
      question: "How do I know you're the best on the market?",
      answer: "We strive to provide the best service possible. We encourage you to try our service and compare it with others to see how we stack up."
    }
  ];

  const getPriceId = (planType, billingCycle) => {
    const priceIds = {
      premium: {
        monthly: 'price_1PzLJpDqfFzJp3s7vn6hphXV',
        yearly: 'price_1PzLJpDqfFzJp3s7x4NmekBS',
      },
      deluxe: {
        monthly: 'price_1PzLJpDqfFzJp3s7koEbSc98',
        yearly: 'price_1PzLJpDqfFzJp3s72Xunoozd',
      },
    };
    return priceIds[planType][billingCycle];
  };

  const handlePlanSelect = (planType) => {
    setSelectedPlan(planType);
  };

  const handleSubscribe = async (userId, selectedPriceId) => {
    if (!selectedPlan) return;

    if (!loggedIn && !userId) {
      navigate('/login');
      return;
    }

    setLoading(true);
    try {
      const stripe = await stripePromise;
      const priceId = selectedPriceId || getPriceId(selectedPlan.toLowerCase(), selectedBilling.toLowerCase());
      const idVar = userId || user['_id'];

      const response = await axiosRequest('post', 'create-checkout-session', {
        priceId: priceId,
        userId: idVar
      });

      if (response.data && response.data.sessionId) {
        const result = await stripe.redirectToCheckout({
          sessionId: response.data.sessionId,
        });

        if (result.error) {
          console.error(result.error);
        }
      } else {
        console.error('Failed to create checkout session');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  if (params.userId) {
    return <div></div>;
  }

  return (
    <DashboardLayout title={''} headerStyle={{ padding: '0px' }}>
      <div className={styles.topRow}>
        <div className={styles.container}>
          <header className={styles.header}>
            <div className={styles.content}>
              <h1>Join our community and discover the amazing AI BUDDY experience!</h1>
              <p>Upgrade to deluxe today and get access to generating images with your own perfect companion!</p>
            </div>
            <div className={styles.imageGrid}></div>
          </header>
          <div className={styles.ratingSection}>
            <div className={styles.rating}>
              <span>⭐⭐⭐⭐⭐</span>
              <p>Rated 4.8/5</p>
            </div>
            <div className={styles.users}>
              <span>🌎</span>
              <p>Join the huge community across the globe!</p>
            </div>
          </div>

          <div className={styles.planSelector}>
            <h2>Choose your Plan</h2> 
            <p>100% anonymous. You can cancel anytime.</p>
            <p><b>Use code SUMMER50 at checkout</b></p>
            <div className={styles.billingToggle}>
              <button
                className={selectedBilling === 'monthly' ? styles.active : ''}
                onClick={() => setSelectedBilling('monthly')}
              >
                Monthly billing
              </button>
              <button
                className={selectedBilling === 'yearly' ? styles.active : ''}
                onClick={() => setSelectedBilling('yearly')}
              >
                Yearly billing
                <span className={styles.discount}>-30%</span>
              </button>
            </div>
          </div>

          <div className={styles.plans}>
            <div
              className={`${styles.plan} ${selectedPlan === 'Premium' ? styles.selectedPlan : ''}`}
              onClick={() => handlePlanSelect('Premium')}
            >
              <h3>Premium</h3>
              <p className={styles.price}>
                ${selectedBilling === 'monthly' ? '9' : '99'}<span>/{selectedBilling === 'monthly' ? 'month' : 'year'}</span>
              </p>
              <p className={styles.description}>Premium models and chat history. All the fun at the price of a coffee.</p>
              <ul>
                <li>5,000 messages / month</li>
                <li>Chat History</li>
                <li>Premium Models</li>
                <li>Custom Characters</li>
              </ul>
            </div>
            <div
              className={`${styles.plan} ${styles.bestValue} ${selectedPlan === 'Deluxe' ? styles.selectedPlan : ''}`}
              onClick={() => handlePlanSelect('Deluxe')}
            >
              <span className={styles.bestValueTag}>Best Value</span>
              <h3>Deluxe</h3>
              <p className={styles.price}>
                ${selectedBilling === 'monthly' ? '14' : '149'}<span>/{selectedBilling === 'monthly' ? 'month' : 'year'}</span>
              </p>
              <p className={styles.description}>All-access - Premium chats. Bestseller for a reason 😉</p>
              <ul>
                <li>20,000 messages / month</li>
                <li>Chat History</li>
                <li>Premium Models</li>
                <li>Custom Characters</li>
                <li>8K Memory</li>
              </ul>
            </div>
          </div>

          <div className={styles.subscribeButtonContainer}>
            <button
              className={styles.checkoutButton}
              onClick={() => handleSubscribe()}
              disabled={!selectedPlan || loading}
            >
              {loading ? 'Processing...' : `Subscribe to ${selectedPlan || 'Selected Plan'}`}
            </button>
          </div>

          <div className={styles.securityInfo}>
            <p>🔒 Safe & Secure Payments</p>
            <p>💳 Privacy in Bank transactions</p>
            <p>Trusted by <strong style={{ color: '#635BFF' }}>Stripe</strong></p>
          </div>

          <div className={styles.faqSection}>
            <h2>Frequently Asked Questions</h2>
            {faqItems.map((item, index) => (
              <div key={index} className={styles.faqItem}>
                <button onClick={() => toggleFAQ(index)}>
                  {item.question}
                  <span>{openFAQ === index ? '▲' : '▼'}</span>
                </button>
                {openFAQ === index && <p>{item.answer}</p>}
              </div>
            ))}
          </div>

          <footer className={styles.footer}>
            <p>Didn't find what you are looking for? Reach out to us on Discord</p>
          </footer>
        </div>
      </div>
    </DashboardLayout>
  );
}